import React, { useState } from 'react';
import './Login.css';
import backgroundImage from '../Hexagon.jpg';
import { Link, useNavigate , useLocation } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emptyFields, setEmptyFields] = useState([]);
  const [message, setMessage] = useState('');
  const navigate=useNavigate()
  const location = useLocation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmptyFields(emptyFields.filter((field) => field !== 'email'))
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setEmptyFields(emptyFields.filter((field) => field !== 'password'))
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fieldsToCheck = [];

    if (email === '') {
      fieldsToCheck.push('email');
    }

    if (password === '') {
      fieldsToCheck.push('password');
    }

    setEmptyFields(fieldsToCheck);

    if (fieldsToCheck.length === 0) {
      let obj = {
        email,
        password,
      };
      console.log("backend url checkpoint", `${process.env.REACT_APP_BACKEND_BASE_URL}`)
//${process.env.REACT_APP_BACKEND_BASE_URL}/user/login
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((data) => {
          setMessage(data.msg)
          localStorage.setItem("token", data.token) 
          localStorage.setItem("email", JSON.stringify(email))
          setTimeout(()=>{
            if (location.state && location.state.returnUrl) {
              navigate(location.state.returnUrl);
              console.log(data.isApproved)
            } else if (data.role==="recruiter"){
              navigate("/search-resumes"); 
              
            }else if(data.role==="client"){
              navigate("/ClientDashboard")
              
            }else if(data.role === "company"){
              navigate("/companySearch")
            }
            else if(data.role==="admin"){
              navigate("/AdminDash")
              
            }
            else if(data.role==="jobseeker" && data.isFirstLogin===false ){
              navigate("/Jobseeker")
            }else if(data.role==="jobseeker" && data.isFirstLogin===true){
              navigate("/myprofile")
            }
          },1000)
        })
        .catch((err) => {
          console.log(err.message);
        });

      // setTimeout(() => {
      //   setEmail('');
      //   setPassword('');
      //   setEmptyFields([]);
      // }, 1000);
    }
  };

  return (
    <div
      className="login-most-outer-div"
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(90%)'
      }}
    >
      <div className="login-form-container ">
      <center>
    
      </center>
        <center>
        
          <h1 className="form-header">Sign In</h1>
        </center>
        {message && (
                    <div className="mt-3 alert alert-info text-center " style={{marginRight:"19px"}}>{message}</div>
                )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className={`input ${emptyFields.includes('email') ? 'invalid-field' : ''}`}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className={`input ${emptyFields.includes('password') ? 'invalid-field' : ''}`}
            />
          </div>
          <div className="form-group">
            <Link to={"/ForgetEmail"} style={{ textDecoration: 'none' }}>Forgot password?</Link>
            <button type="submit" className="login-button">
              LOGIN
            </button>
          </div>
          <p>
            Don't have an account? <a href="/Signup">SignUp</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
