import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('recruiter');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);

  const navigate = useNavigate();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setIsFormSubmitted(false);
  };

  const handleTermsConditionsChange = (e) => {
    setTermsConditionsChecked(e.target.checked);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setIsFormSubmitted(false);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    setIsFormSubmitted(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsFormSubmitted(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsFormSubmitted(false);
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password || !userType || !firstName || !lastName || (userType === 'company' && !companyName)) {
      setIsFormSubmitted(true);
      setMessage('Please fill in all required fields.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const companyEmailPattern = /^[^\s@]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    const personalEmailDomains = ['gmail.com', 'yahoo.com', 'outlook.com'];

    const emailDomain = email.split('@')[1];

    if (!emailPattern.test(email)) {
      setIsFormSubmitted(true);
      setMessage('Please enter a valid email.');
      return;
    }

    if (userType === 'company' && (!companyEmailPattern.test(email) || personalEmailDomains.includes(emailDomain))) {
      setIsFormSubmitted(true);
      setMessage('Please enter a valid company email.');
      return;
    }

    let obj = {
      firstName,
      lastName,
      email,
      password,
      spot:"regularSeeker",
      role: userType,
      ...(userType === 'company' && { companyName }),
    };
    
    localStorage.setItem("role", JSON.stringify(userType));
//${process.env.REACT_APP_BACKEND_BASE_URL}/user/register
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(`${data.msg} redirecting to login...`);
       
        if (data.msg === "User Successfully Registered") {
          fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/registerCompany`, {
            method: "POST",
            headers:{
              "content-type": "application/json",
            },
            body: JSON.stringify({
              firstName,
              adminid: data.id,
              ...(userType === 'company' && { companyName })
            })
          })
          setTimeout(() => {
            navigate("/Login");
          }, 2000);
          
        } else if (data.msg === "User Already Registered, Please Login !") {
          setTimeout(() => {
            navigate("/signin");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center vh-100">
      <div className="signup-form-container border p-4" style={{width:"400px",justifyContent:"center"}}>
        <h1 className="text-center text-gray">Register</h1>
        {message && (
          <div className="mt-3 alert alert-info text-center">{message}</div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              className={`form-control ${isFormSubmitted && !firstName ? 'is-invalid' : ''}`}
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              className={`form-control ${isFormSubmitted && !lastName ? 'is-invalid' : ''}`}
            />
          </div>
          {userType === 'company' && (
            <div className="form-group">
              <label>Company Name:</label>
              <input
                type="text"
                value={companyName}
                onChange={handleCompanyNameChange}
                className={`form-control ${isFormSubmitted && !companyName ? 'is-invalid' : ''}`}
              />
            </div>
          )}
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={handleEmailChange}
              className={`form-control ${isFormSubmitted && !email ? 'is-invalid' : ''}`}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className={`form-control ${isFormSubmitted && !password ? 'is-invalid' : ''}`}
            />
          </div>
          <div className="form-group">
            <div className="user-type-group">
              {/* <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="recruiter"
                  checked={userType === 'recruiter'}
                  onChange={handleUserTypeChange}
                />
                <label className="form-check-label">Recruiter</label>
              </div> */}
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="jobseeker"
                  checked={userType === 'jobseeker'}
                  onChange={handleUserTypeChange}
                />
                <label className="form-check-label">Job Seeker</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="company"
                  checked={userType === 'company'}
                  onChange={handleUserTypeChange}
                />
                <label className="form-check-label">Company</label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <label className="form-check-label" htmlFor="termsConditionsCheckbox">
                By Clicking on register you accept our standard <a href="/terms" target="_blank">Terms & Conditions</a> and our <a href="/privacypolicy" target="_blank">privacy policy</a>.
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="text-center">
              <button type="submit" style={{background:"#0A65CC",color:"white",fontWeight:"600",padding:" 12px 24px 13px",border:"none",borderRadius:"4px",fontSize:"16px",fontFamily:"sans-serif"}}>
                REGISTER
              </button>
            </div>
          </div>
          <p>
            Already have an account? <a href="/Login" style={{ textDecoration: 'none' }}>Sign In</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Signup;
